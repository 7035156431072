import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  static classes = ["hidden"]

  open() {
    if (this.hasModalTarget && this.modalTarget.classList.contains("hidden")) {
      this.modalTarget.classList.remove("hidden")
    }
  }

  close() {
    if (this.hasModalTarget && !this.modalTarget.classList.contains("hidden")) {
      this.modalTarget.classList.add("hidden")
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      console.log("closeWithKeyboard")
      this.close()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }

    this.close()
  }
}
